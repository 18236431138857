<template>
	<div data-cy="ssnit">
		<template>
			<v-snackbar
				:timeout="5000"
				:value="true"
				top
				:color="isError ? 'error' : 'success'"
				center
				v-model="snackBar"
				outlined
				text
				elevation="1"
			>
				{{ snackBarText }}
			</v-snackbar>
		</template>
		<div v-if="isLoading">
			<spinner></spinner>
		</div>
		<v-data-table
			data-cy="ssnit-table"
			:headers="headers"
			:items="pensions"
			class="elevation-1"
			hide-default-footer
		>
			<template v-slot:top>
				<v-dialog
					data-cy="ssnit-dialog"
					v-model="dialog"
					max-width="500px"
					persistent
				>
					<v-card data-cy="ssnit-card">
						<v-card-title class="text-h5 py-5 justify-center">
							<span>{{ formTitle }}</span>
						</v-card-title>

						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12" sm="6" md="6">
										<v-text-field
											data-cy="ssnit-label"
											dense
											outlined
											disabled
											v-model="editedItem.label"
											label="Label"
										></v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="6">
										<v-text-field
											data-cy="ssnit-rate"
											dense
											outlined
											v-model="editedItem.percentage"
											label="Rate (%)"
										></v-text-field>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								data-cy="cancelBtn"
								color="secondary darken-1"
								@click="close"
							>
								Cancel
							</v-btn>
							<v-btn
								data-cy="ssnit-save"
								color="primary darken-1"
								@click="save"
							>
								{{ btnTitle }}
							</v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-icon
					data-cy="ssnit-edit"
					color="primary"
					size="24"
					dense
					class="mr-2"
					@click="editItem(item)"
				>
					mdi-pencil
				</v-icon>
			</template>
			<template v-slot:no-data> NO DATA AVAILABLE </template>
		</v-data-table>
	</div>
</template>

<script>
import { ALL_PENSIONS } from "../services/graphql/queries/all_queries";
import { createHeaders } from "../services/functions";

export default {
	data: () => ({
		dialog: false,
		dialogDelete: false,
		isError: true,
		snackBar: false,
		snackBarText: "",
		isLoading: false,
		headers: createHeaders("ssnit"),
		pensions: [],
		editedIndex: -1,
		editedItem: {},
		defaultItem: {},
	}),
	computed: {
		formTitle() {
			return this.editedIndex === -1
				? "Add Statutory Deductions"
				: "Edit Statutory Deductions";
		},
		btnTitle() {
			return this.editedIndex ? "Add" : "Update";
		},
	},
	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		},
	},
	created() {
		this.initialize();
	},
	methods: {
		fetchPensions() {
			this.isLoading = true;
			this.$apollo
				.query({
					query: ALL_PENSIONS,
				})
				.then(({ data }) => {
					this.pensions = data.listPensions.map((pension) => ({
						id: pension.id,
						label: pension.label,
						percentage: pension.percentage,
					}));
				})
				.catch((error) => {
					console.log(error);
				});
			this.isLoading = false;
		},
		initialize() {
			this.fetchPensions();
		},
		editItem(item) {
			this.editedIndex = this.pensions.indexOf(item);
			this.editedItem = { ...item };
			this.dialog = true;
		},

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    async save() {
      if (this.editedIndex > -1) {
        Object.assign(this.pensions[this.editedIndex], this.editedItem);
        await this.$store.dispatch("statutory/editSsnit", this.editedItem);
        this.isError = false;
        this.snackBar = true;
        this.snackBarText = "SSNIT Edited Successfully";
      } else {
        this.pensions.push(this.editedItem);
        await this.$store.dispatch("statutory/createSsnit", this.pensions);
        this.isError = false;
        this.snackBar = true;
        this.snackBarText = " SSNIT Created Successfully";
      }
      this.close();

			await this.initialize();
		},
	},
};
</script>

<style scoped>
.col-12,
.col.sm-12,
.col-md-12 {
	padding: 0 10px !important;
}
</style>
