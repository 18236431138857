import { render, staticRenderFns } from "./SsnitComponent.vue?vue&type=template&id=0a4f016c&scoped=true"
import script from "./SsnitComponent.vue?vue&type=script&lang=js"
export * from "./SsnitComponent.vue?vue&type=script&lang=js"
import style0 from "./SsnitComponent.vue?vue&type=style&index=0&id=0a4f016c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a4f016c",
  null
  
)

export default component.exports