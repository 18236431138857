const required = (propertyType) => {
  if (propertyType) {
    return (v) =>
      (v) && (v.length) > 0 && (v !== undefined) ||
      `You must provide ${propertyType}`;
  }
};

const selectionRequired = (propertyType) => {
  return (v) => !!v || `${propertyType} is required`;
};

const currencyFormat = (val) => {
  if (val !== "" && val !== null) {
    return (v) => (v?.match(/(^[\d.]+$)/) || "Must be a currency format");
  }
};

export default {
  required,
  selectionRequired,
  currencyFormat,
};
